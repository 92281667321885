<template>
  <div class="about-us-section">
    <!-- <div class="team-section">
      <h2 class="team-section__title">The Founders</h2>
      <p class="team-section__sub-title">
        Glocal’s team is working to meaningfully improve the digital information
        environment.
      </p>
      <a-row :gutter="[50, 45]" class="team-section__section">
        <a-col :xs="24" :sm="12" :md="12" :lg="11" :xl="9">
          <div class="team-card">
            <div class="team-card__bg-image team-card__bg-image-1"></div>
            <h1 class="team-card__title">
              Dr. Elena Taube Bailey | Founder
            </h1>
            <p class="team-card__description">
              Dr. Elena Taube Bailey leverages over 20 years of combined
              experience in the intelligence community (CIA, DOS, DoD, and ODNI)
              and academia (teaching at the National Defense University and
              Georgetown University). Dr. Bailey is a subject expert on
              intelligence, counterintelligence, cyber psychology, behavioral
              psychology, strategic communications and media, propaganda,
              information warfare, counterterrorism, and religious issues in the
              Middle East, Africa, and Eastern Europe.
            </p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="12" :md="12" :lg="11" :xl="9">
          <div class="team-card">
            <div class="team-card__bg-image team-card__bg-image-2"></div>
            <h1 class="team-card__title">
              David Taube | Founder
            </h1>
            <p class="team-card__description">
              David Taube, CFA, brings 17 years of business experience
              delivering transformational growth and value creation as a Senior
              Business Executive, Private Equity investor, and Strategy
              Consultant at Boston Consulting Group (BCG). His expertise lies in
              driving breakthrough growth by recruiting and developing high-
              performance and unconventional teams in the technology, media, and
              telecommunications sectors.
            </p>
          </div>
        </a-col>
      </a-row>
    </div> -->
    <div class="about-section">
      <div class="about-section__content">
        <h1>BUILT BY THE BEST</h1>
        <h2>GLOCAL IS GLOBAL</h2>
        <p>
          Glocal's News SuperIntelligence environment is developed by business and intelligence leaders who leveraged their decades of experience in leading institutions such as the CIA, Boston Consulting Group, and academia to deliver a world class superIntelligence platform.
          <br />
          <br />
          This decentralized environment is made possible by people who seek to elevate the digital information environment and a highly effective network of designers, engineers and developers from around the world:
          <br />
        </p>
        <ul>
          <li><span></span>North America</li>
          <li><span></span>Europe</li>
          <li><span></span>Middle East</li>
          <li><span></span>North Africa</li>
          <li><span></span>South Asia</li>
        </ul>
        <button class="btn" @click="openModal()">About the Founders</button>
      </div>
      <video playsinline muted autoplay loop type="video/webm" class="about-section__bg-video">
        <source
          src="https://imagespictures.nyc3.digitaloceanspaces.com/Assets/global_team.webm"
        />
        <track kind="captions" srclang="en" label="glocal_global_team">
      </video>
    </div>
  </div>
  <a-modal :visible="visible" @cancel="closeModal()" class="founders-modal" :title="null" :footer="null">
    <div class="founders-modal-container">
      <div class="founders-modal-container__founder">
        <div class="founders-modal-container__founder--image">
          <img src="@/assets/images/picture-elena-2.png" alt="Image" />
        </div>
        <div class="founders-modal-container__founder--content">
          <h1>Dr. Elena T. Bailey and David Taube co-founded Glocal with the shared mission to significantly improve the world’s information environment pertaining to news and current events.</h1>
          <p>
            <span>Their vision is to create a superintelligent digital space, powered by humans and AI, for news and current events.</span>
            <span>Elena and David drew upon their unique experience in intelligence, communications, psychology, defense, and business from leading institutions such as the CIA, Department of Defense, Boston Consulting Group, and academia to deliver the world’s first superintelligence platform.</span>
            <span>In today’s real-time information environment, it is not sufficient to receive sporadic pieces of news on a daily basis. What is needed is <strong>context</strong>. Context helps people understand disparate pieces of information so that each person can make make the best decision and solve specific problems.</span>
            <span><strong>Mission</strong>- deliver an unparallel news superintelligence environment powered by human’s and AI, to help people make best decisions to solve specific problems.</span>
            <strong>Vision</strong>- elevate the information environment to help people solve important problems.
          </p>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'AboutUsSection',
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    openModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
}
</script>

<style lang="scss">
.about-us-section {
  background-color: $color-black;
  padding: 5rem 0;
  position: relative;
  &::after {
    background: radial-gradient(circle, #315ef23d, transparent);
    width: 46rem;
    height: 46rem;
    border-radius: 100%;
    position: absolute;
    content: "";
    left: -23rem;
    top: 60%;
    transform: translateY(-60%);
    @include respond(tab-port) {
      top: 75%;
      transform: translateY(-75%);
    }
  }
  // &::before {
  //   background: radial-gradient(circle, #ffffff26, transparent);
  //   width: 46rem;
  //   height: 46rem;
  //   border-radius: 100%;
  //   position: absolute;
  //   content: "";
  //   right: -23rem;
  //   top: -5rem;
  // }
  .team-section {
    padding: 0 11rem;
    @include respond(tab-port) {
      padding: 0 4rem;
    }
    &__title {
      font-family: $font-primary-bold;
      color: $color-white;
      font-weight: 700;
      line-height: 8.2rem;
      font-size: 6rem;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 2.4rem;
      @include respond(laptop-small) {
        font-size: 4.4rem;
        line-height: 5rem;
        max-width: 100%;
      }
      @include respond(tab-port) {
        font-size: 4.4rem;
        line-height: 5rem;
        max-width: 100%;
      }
      @include respond(phone-x-small) {
        line-height: 8.2rem;
        font-size: 6rem;
      }
    }
    &__sub-title {
      font-family: $font-primary;
      color: $color-white;
      line-height: 4.6rem;
      font-size: 3.4rem;
      text-align: center;
      opacity: 0.6;
      max-width: 87rem;
      display: block;
      margin: 0 auto;
    }
    &__section {
      padding: 10rem 0 0;
      justify-content: center;
      position: relative;
      z-index: 1;
      .team-card {
        &__bg-image {
          width: 27.7rem;
          height: 27.7rem;
          border-radius: 100%;
          background-position: top;
          background-size: cover;
          background-repeat: no-repeat;
          margin: 0 auto 5.1rem;
        }
        &__bg-image-1 {
          background-image: url("../../assets/images/picture-elena.png");
        }
        &__bg-image-2 {
          background-image: url("../../assets/images/picture-david.png");
        }
        &__title {
          font-family: $font-primary-medium;
          color: $color-white;
          font-weight: 700;
          line-height: 4.1rem;
          font-size: 3rem;
          text-align: center;
          margin-bottom: 2.4rem;
          @include respond(phone-x-small) {
            font-size: 3.5rem;
            line-height: 4.8rem;
          }
        }
        &__description {
          font-family: $font-primary;
          color: $color-white;
          line-height: 2.7rem;
          font-size: 2rem;
          opacity: 0.5;
          text-align: center;
          @include respond(phone-x-small) {
            font-size: 2.2rem;
            line-height: 2.9rem;
          }
        }
      }
    }
  }
  .about-section {
    padding: 20rem 11rem;
    position: relative;
    width: 100%;
    @include respond(laptop-large) {
      padding: 20rem 7.1rem;
    }
    @include respond(tab-port) {
      padding: 15rem 4rem;
    }
    &__content {
      position: relative;
      z-index: 1;
      max-width: 61rem;
      h1 {
        font-family: $font-primary-medium;
        color: $color-primary;
        font-weight: 700;
        line-height: 3.3rem;
        font-size: 2.4rem;
        text-transform: uppercase;
        margin-bottom: 1.7rem;
        text-align: left;
        @include respond(phone-x-small) {
          font-size: 3rem;
          line-height: 4rem;
        }
      }
      h2 {
        font-family: $font-primary-bold;
        color: $color-white;
        font-weight: 700;
        line-height: 8.2rem;
        font-size: 6rem;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 5.6rem;
        @include respond(laptop-small) {
          font-size: 4.4rem;
          line-height: 5rem;
          max-width: 100%;
        }
        @include respond(tab-port) {
          font-size: 4.4rem;
          line-height: 5rem;
          max-width: 100%;
          margin-bottom: 4rem;
        }
      }
      p {
        font-family: $font-primary;
        color: $color-white;
        line-height: 2.6rem;
        font-size: 2rem;
        margin-bottom: 0;
        text-align: left;
        max-width: 55rem;
        @media (max-width: 991px) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: 5.6rem;
        max-width: 48.3rem;
        li {
          font-family: $font-primary;
          color: $color-white;
          line-height: 2.8rem;
          font-size: 2.4rem;
          margin-bottom: 2.4rem;
          text-align: left;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.8rem;
            height: 1.8rem;
            width: 1.8rem;
            box-shadow: #7b9aff85 0px 0px 10px 2px;
            background-color: #7b9aff85;
            border-radius: 100%;
            position: relative;
            &::before {
              position: absolute;
              width: 0.8rem;
              height: 0.8rem;
              background-color: #7b99ff;
              border-radius: 100%;
              content: "";
            }
          }
        }
        @media (max-width: 991px) {
          max-width: 100%;
          li {
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }
      }
      .btn {
        background-color: $color-primary;
        color: $color-white;
        font-family: $font-primary-medium;
        line-height: normal;
        font-size: 1.8rem;
        padding: 0 2rem;
        height: 4.6rem;
        border: none;
        outline: none;
        margin-top: 2rem;
        cursor: pointer;
        @include respond(tab-port) {
          font-size: 2.2rem;
          height: 5.5rem;
          margin-top: 3rem;
        }
      }
    }
    &__bg-video {
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
      top: 0;
      object-fit: cover;
    }
  }
}
.founders-modal {
  width: 100% !important;
  max-width: 135rem;
  padding: 0 2rem 8rem;
  top: 8rem;
  .ant-modal-content {
    .ant-modal-close {
      margin: 1.5rem 1.5rem 0 0;
      @include respond(tab-port) {
        .ant-modal-close-x {
          .anticon {
            font-size: 3rem !important;
          }
        }
      }
    }
    .ant-modal-body {
      .founders-modal-container {
        padding: 2rem;
        &__founder {
          display: flex;
          align-items: flex-start;
          gap: 2.5rem;
          &--image {
            height: 30rem;
            width: 24rem;
            overflow: hidden;
            border-radius: 1rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          &--content {
            width: calc(100% - 26.5rem);
            padding: 1rem 5rem 1rem 0;
            h1 {
              font-family: $font-primary-bold;
              color: $color-black;
              line-height: 3rem;
              font-size: 2.2rem;
              margin-bottom: 1.7rem;
              text-align: left;
            }
            p {
              font-family: $font-primary;
              color: $color-black;
              line-height: 2.5rem;
              font-size: 1.8rem;
              margin-bottom: 0;
              text-align: left;
              opacity: 1;
              span {
                display: inline-block;
                margin-bottom: 1.5rem;
              }
            }
          }
        }
        @include respond(tab-port) {
          &__founder {
            flex-direction: column;
            padding: 1rem;
            align-items: flex-start;
            &--image {
              width: 20rem;
              height: 20rem;
              border-radius: 100%;
              background: #151515;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            &--content  {
              width: 100%;
              padding: 0;
              h1 {
                font-size: 2.8rem;
                line-height: 3.4rem;
              }
              p {
                font-size: 2.2rem;
                line-height: 2.8rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
